import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import PrivateAppLayout from '../layouts/PrivateAppLayout';
import { NotFound } from '../modules/NotFound';
import AddBuHead from '../modules/Bu/Component/AddBuHead';
import { ErrorBoundary } from '../components/ErrorBoundary';
import BudgetReport from '../modules/BudgerReport/BudgetReport';

const LoginPage = lazy(() => import('../modules/Login/Login'));
const DashbaordPage = lazy(() => import('../modules/Dashboard/Dashboard'));
const CategoriesPage = lazy(() => import('../modules/Categories/Categories'));
const SubCategoriesPage = lazy(
  () => import('../modules/SubCategories/SubCategories')
);
const BadgesPage = lazy(() => import('../modules/Badges/Badges'));
const DesignationsPage = lazy(() => import('../modules/Designations/Designations'));
const KRAPage = lazy(() => import('../modules/KRA/KRA'));
const UsersPage = lazy(() => import('../modules/Users/Users'));
const BuPage = lazy(() => import('../modules/Bu/Bu'));
const EligibilityPage = lazy(
  () => import('../modules/Eligibility/Eligibility')
);
const AppraisalPage = lazy(
  () => import('../modules/AppraisalAllocation/AppraisalAllocation')
);
const ReportPage = lazy(
  () => import('../modules/Reports/Reports')
);
const SlabMapping = lazy(() => import('../modules/SlabMapping/SlabMapping'))
const ScoreMappingPage = lazy(
  () => import('../modules/ScoreMapping/ScoreMapping')
);
const AddCategoryPage = lazy(
  () => import('../modules/Categories/Component/AddCategoriesForm')
);
const AddSubCategoryPage = lazy(
  () => import('../modules/SubCategories/Component/AddSubCategroyForm')
);
const AddBadgeForm = lazy(
  () => import('../modules/Badges/Component/AddBadgeForm')
);
const AddKRAForm = lazy(() => import('../modules/KRA/Component/AddKRAForm'));
const ChangePassword = lazy(
  () => import('../modules/Login/Component/ChangePassword')
);

const AppRouter = () => (
  <Routes>
    {/* Private Route */}
    {/* <Route path="/" element={<PrivateAppLayout><DashbaordPage /></PrivateAppLayout>} /> */}
    <Route
      path='/dashboard'
      element={
        <PrivateAppLayout>
          <DashbaordPage />
        </PrivateAppLayout>
      }
    />
    <Route
      path='/categories'
      element={
        <PrivateAppLayout>
          <CategoriesPage />
        </PrivateAppLayout>
      }
    />
    <Route
      path='/sub-categories'
      element={
        <PrivateAppLayout>
          <SubCategoriesPage />
        </PrivateAppLayout>
      }
    />
    <Route
      path='/badges'
      element={
        <PrivateAppLayout>
          <BadgesPage />
        </PrivateAppLayout>
      }
    />
    <Route
      path='/score-mapping'
      element={
        <PrivateAppLayout>
          <ScoreMappingPage />
        </PrivateAppLayout>
      }
    />
    <Route
      path='/slab-mapping'
      element={
        <PrivateAppLayout>
          <SlabMapping />
        </PrivateAppLayout>
      }
    />
    <Route 
      path="/designations" 
      element={
      <PrivateAppLayout>
        <DesignationsPage />
        </PrivateAppLayout>
      } 
    />
    <Route
      path='/kra'
      element={
        <PrivateAppLayout>
          <KRAPage />
        </PrivateAppLayout>
      }
    />
    <Route
      path='/users'
      element={
        <PrivateAppLayout>
          <UsersPage />
        </PrivateAppLayout>
      }
    />
    <Route
      path='/bu'
      element={
        <PrivateAppLayout>
          <BuPage />
        </PrivateAppLayout>
      }
    />
    <Route
      path='/eligibility'
      element={
        <PrivateAppLayout>
          <EligibilityPage />
        </PrivateAppLayout>
      }
    />
    <Route
      path='/appraisal'
      element={
        <PrivateAppLayout>
          <AppraisalPage />
        </PrivateAppLayout>
      }
    />
    <Route
      path='/budget-report'
      element={
        <PrivateAppLayout>
          <BudgetReport />
        </PrivateAppLayout>
      }
    />
    <Route
      path='/categories/create-category'
      element={
        <PrivateAppLayout>
          {' '}
          <AddCategoryPage />
        </PrivateAppLayout>
      }
    />
    <Route
      path='/sub-categories/create-sub-category'
      element={
        <PrivateAppLayout>
          {' '}
          <AddSubCategoryPage />
        </PrivateAppLayout>
      }
    />
    <Route
      path='/badges/create-badge'
      element={
        <PrivateAppLayout>
          {' '}
          <AddBadgeForm />
        </PrivateAppLayout>
      }
    />
    <Route
      path='/kra/create-kra'
      element={
        <PrivateAppLayout>
          {' '}
          <AddKRAForm />
        </PrivateAppLayout>
      }
    />
    <Route
      path='/bu/create-buhead'
      element={
        <PrivateAppLayout>
          <AddBuHead />
        </PrivateAppLayout>
      }
    />
    {/* Public Route */}
    <Route path='/login' element={<LoginPage />} />
    <Route
      path='/chnage-password'
      element={
        <PrivateAppLayout>
          {' '}
          <ChangePassword />
        </PrivateAppLayout>
      }
    />
    {/* {/* Public Route */}
    <Route path='/' element={<LoginPage />} />
    <Route path='/*' element={<NotFound />} />
  </Routes>
);

// const AppRouter = () => {
//   const authAppRouter = createBrowserRouter([
//     {
//       path: '/',
//       element: <AuthRoute />,
//       children: [
//         {
//           path: '/',
//           element: <PrivateAppLayout children={undefined} />,
//           children: [
//             {
//               path: "/dashboard", element: <DashbaordPage />,
//             },
//             {
//               path: '/categories',
//               element: <CategoriesPage />,
//             },
//             {
//               path: '/sub-categories',
//               element: <SubCategoriesPage />,
//             },
//             {
//               path: '/badges',
//               element: <BadgesPage />,
//             },
//             {
//               path: '/score-mapping',
//               element: <ScoreMappingPage />,
//             },
//             {
//               path: '/kra',
//               element: <KRAPage />,
//             },
//             {
//               path: '/users',
//               element: <UsersPage />,
//             },
//             {
//               path: '/create-category',
//               element: <AddCategoryPage />,
//             },
//             {
//               path: '/create-sub-category',
//               element: <AddSubCategoryPage />,
//             },
//             {
//               path: '/create-badge',
//               element: <AddBadgeForm />,
//             },
//             {
//               path: '/create-kra',
//               element: <AddKRAForm />,
//             },
//           ],
//         },
//       ],
//       errorElement: <ErrorBoundary />,
//     },
//     {
//       path: '/login',
//       element: <LoginPage />,
//     },
//     {
//       path: '/*',
//       element: <NotFound />,
//     },
//   ]);

//   return (<RouterProvider router={authAppRouter} />);
// };

export default AppRouter;
