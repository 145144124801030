import React, { useEffect, useState } from 'react';
import { ListTable } from '../../components';
import { TableColumn, TableData } from '../../components/ListTable';
import { convertArrayOfObjectsToCsv } from '../../components/CsvHelper/CsvHelper';
import { TableSortLabel } from '@mui/material';

const mockData = {
  data: [
    {
      name: 'Gaurav Yadav',
      assignedAppraisal: 20,
      eligibleAppraisal: 35,
      createdAt: '2023-11-29T21:41:38.185Z',
      bu: [
        {
          id: '6567afdfd43353120e3671fe',
          name: 'Node',
        },
      ],
      FY: ['2022-2023'],
      updatedAt: '2024-08-27T05:54:09.736Z',
      id: '6567b012d43353120e36bbf7',
    },
    {
      name: 'Preet Saxena',
      assignedAppraisal: 25,
      eligibleAppraisal: 30,
      bu: [
        {
          id: '6567afdfd43353120e367204',
          name: 'Frontend',
        },
      ],
      FY: ['2022-2023'],
      createdAt: '2024-08-23T07:06:01.436Z',
      id: '66c834d93591fea4d25846c8',
    },
    {
      name: 'Prashant',
      assignedAppraisal: 20,
      eligibleAppraisal: 30,
      bu: [
        {
          id: '6567afdfd43353120e367202',
          name: 'HR',
          _id: '66cdb1d36d980cc2d29f0231',
        },
      ],
      FY: ['2022-2023'],
      createdAt: '2024-09-17T10:27:12.159Z',
      id: '66e95980a4de8e233f5e6f18',
    },
    {
      name: 'Animesh Yadav',
      assignedAppraisal: 20,
      eligibleAppraisal: 30,
      createdAt: '2023-11-29T21:41:38.185Z',
      bu: [
        {
          id: '6567afdfd43353120e3671fe',
          name: 'Node',
          _id: '66cd6a016d980cc2d29eeeee',
        },
      ],
      FY: ['2022-2023'],
      updatedAt: '2024-08-27T05:54:09.736Z',
      id: '6567b012d43353120e36bbf7',
    },
    {
      name: 'Nitin',
      assignedAppraisal: 20,
      eligibleAppraisal: 30,
      bu: [
        {
          id: '6567afdfd43353120e367204',
          name: 'Frontend',
          _id: '66c834d93591fea4d25846ca',
        },
      ],
      FY: ['2022-2023'],
      createdAt: '2024-08-23T07:06:01.436Z',
      id: '66c834d93591fea4d25846c8',
    },
    {
      name: 'Divay',
      assignedAppraisal: 20,
      eligibleAppraisal: 30,
      bu: [
        {
          id: '6567afdfd43353120e367204',
          name: '.net',
          _id: '66c836653591fea4d25847bb',
        },
      ],
      FY: ['2022-2023'],
      createdAt: '2024-08-23T07:12:37.155Z',
      id: '66c836653591fea4d25847b9',
    },
    {
      name: 'Ankush',
      assignedAppraisal: 20,
      eligibleAppraisal: 30,
      bu: [
        {
          id: '6567afdfd43353120e367202',
          name: 'Pixians',
          _id: '66cdb1d36d980cc2d29f0231',
        },
      ],
      FY: ['2022-2023'],
      createdAt: '2024-08-27T05:58:12.011Z',
      updatedAt: '2024-08-27T11:00:35.149Z',
      id: '66cd6af46d980cc2d29ef0b4',
    },
    {
      name: 'Shashank',
      assignedAppraisal: 20,
      eligibleAppraisal: 30,
      bu: [
        {
          id: '6567afdfd43353120e367202',
          name: 'HR',
          _id: '66cdb1d36d980cc2d29f0231',
        },
      ],
      FY: ['2022-2023'],
      createdAt: '2024-09-17T10:27:12.159Z',
      id: '66e95980a4de8e233f5e6f18',
    },
    {
      name: 'Vipin',
      assignedAppraisal: 20,
      eligibleAppraisal: 30,
      createdAt: '2023-11-29T21:41:38.185Z',
      bu: [
        {
          id: '6567afdfd43353120e3671fe',
          name: 'Node',
          _id: '66cd6a016d980cc2d29eeeee',
        },
      ],
      FY: ['2022-2023'],
      updatedAt: '2024-08-27T05:54:09.736Z',
      id: '6567b012d43353120e36bbf7',
    },
  ],
  message: 'List of BU ',
  status: 200,
};

const BudgetReport: React.FC = () => {
  const [data, setData] = useState<TableData[]>([]);
  const [buOptions, setBuOptions] = useState<string[]>([]);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');

  function fetchData() {
    const transformedData = mockData?.data?.map((item: any) => {
      return {
        name: item?.name,
        buHead: item?.bu?.map((head: any) => head?.name).join(', '),
        fy: item?.FY.join(', '),
        assignedAppraisal: item.assignedAppraisal,
        eligibleAppraisal: item.eligibleAppraisal,
      };
    });
    setData(transformedData);

    const buOptions = mockData.data.flatMap((item) => item.bu.map((bu) => bu.name)).filter((department, index, self) => self.indexOf(department) === index);

    setBuOptions(buOptions);
  }

  useEffect(() => {
    const title = document.querySelector('title');
    if (title) {
      title.innerText = 'Budget Report';
    }
    fetchData();
  }, []);

  const handleSortRequest = (column: string) => {
    const isAsc = orderBy === column && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(column);
  };

  const sortedData = data.slice().sort((a, b) => {
    if (orderBy === 'assignedAppraisal' || orderBy === 'eligibleAppraisal') {
      const aValue = a[orderBy];
      const bValue = b[orderBy];
      if (aValue < bValue) return orderDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return orderDirection === 'asc' ? 1 : -1;
      return 0;
    }
    return 0;
  });

  const columns: TableColumn[] = [
    { header: 'name', accessor: 'name' },
    { header: 'BU', accessor: 'buHead' },
    { header: 'FY', accessor: 'fy' },
    {
      header: (
        <TableSortLabel
          active={orderBy === 'assignedAppraisal'}
          direction={orderDirection}
          onClick={() => handleSortRequest('assignedAppraisal')}
        >
          Assigned appraisal
        </TableSortLabel>
      ),
      accessor: 'assignedAppraisal',
    },
    {
      header: (
        <TableSortLabel
          active={orderBy === 'eligibleAppraisal'}
          direction={orderDirection}
          onClick={() => handleSortRequest('eligibleAppraisal')}
        >
          Eligible appraisal
        </TableSortLabel>
      ),
      accessor: 'eligibleAppraisal',
    },
  ];

  const downloadCsvReport = () => {
    const result = data.map((row) => ({
      Name: row.name,
      BU: row.buHead,
      FY: row.fy,
      'Assigned Appraisal': row.assignedAppraisal,
      'Eligible Appraisal': row.eligibleAppraisal,
    }));

    const csvData = convertArrayOfObjectsToCsv(result, [
      { label: 'Name', key: 'Name' },
      { label: 'BU', key: 'BU' },
      { label: 'FY', key: 'FY' },
      { label: 'Assigned Appraisal', key: 'Assigned Appraisal' },
      { label: 'Eligible Appraisal', key: 'Eligible Appraisal' },
    ]);

    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'budget_report.csv';
    a.click();
  };

  return (
    <div className='bu-comp-wrap'>
      <ListTable
        columns={columns}
        timeout={5000}
        tabValue={''}
        data={sortedData}
        isStatus={false}
        heading={'Budget Report'}
        buOptions={buOptions}
        downloadCsvReport={downloadCsvReport}
      />
    </div>
  );
};

export default BudgetReport;
